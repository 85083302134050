import axios from "axios";

const resource = "/employee-management/add-employee";

export default {
    post(data) {
        return axios.post(resource, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    }
};