import axios from "axios";

const resource = "/customer-management/list-customer";

export default {
    get(
        searchValue,
        sortAttribute,
        ascending,
        genderFilter,
        ageFilter,
        customerTypeFilter,
        page,
        limit
    ) {
        return axios.get(
            resource + "?searchValue=" +
                searchValue + "&sortAttribute=" +
                sortAttribute + "&ascending=" +
                ascending + "&genderFilter=" + genderFilter +
                "&ageFilter=" + ageFilter + "&customerTypeFilter=" + customerTypeFilter +
                "&page=" + page + "&limit=" + limit
        );
    }
};
