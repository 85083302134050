import axios from "axios";

const resource =
    "report-face-attendance/customer-familiar-counting?dateStart=";

export default {
    get(dateStart, dateEnd, group, compareType) {
        return axios.get(
            resource +
                dateStart +
                "&dateEnd=" +
                dateEnd +
                "&page=1&limit=100" +
                "&groupID=" +
                group +
                "&compareType=" +
                compareType
        );
    }
};
