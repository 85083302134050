import axios from "axios";

const resource =
    "/customer-management/customer-behavior-analyst?time=";

export default {
    get(type) {
        return axios.get(
            resource +
            type
        );
    }
};
