import axios from "axios";

const resource = "/department-management/list-department-by-group?listGroup=";

export default {
    
    get(groupID) {
        return axios.get(resource + groupID);
    },
};
