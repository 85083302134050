import axios from "axios";
import utils from "@/utils";

const resource = "/report-heatmap/report-heatmap-by-date-and-camera?";

export default {
    get(cameraID, dateStart, dateEnd, compare) {
        const paramsObject = {
            cameraID: cameraID,
            dateStart: dateStart,
            dateEnd: dateEnd,
            compare: compare
        };

        const queryString = utils.serializeQueryParams(paramsObject);
        
        return axios.get(`${resource}${queryString}`);
    },
};
