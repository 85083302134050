import axios from "axios";

const resource = "customer/get-suspicious-face";

export default {
    get() {
        return axios.get(
            resource 
        );
    },
};
