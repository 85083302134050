import axios from "axios";

const resource = "customer-management/get-customer-record-detail?timestamp=";

export default {
    get(timestamp, groupName, id) {
        return axios.get(
            resource +
                timestamp +
                "&groupName=" +
                groupName +
                "&id=" +
                id
        );
    },
};
