import axios from 'axios'

const resource = "/report-face-attendance/export-excel-attendance?dateStart="

export default {
    post(dateStart, dateEnd, groupID, sortAtribute, ascending, searchValue, lang) {
        return axios.post(resource + dateStart +
            '&dateEnd=' + dateEnd +
            '&groupID=' + groupID +
            '&sortAtribute=' + sortAtribute +
            '&ascending=' + ascending +
            '&searchValue=' + searchValue +
            '&lang=' + lang,
            null, { responseType: "blob" })
    }
}