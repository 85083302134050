import axios from "axios";

const resource = "/department-management/update-department/";

export default {
    put(id, data) {
        return axios.put(resource+id, data, {
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
};
