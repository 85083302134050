import axios from "axios";

const resource = "/people-counting-management/greetings?groupID=";

export default {
    get(groupID, dateStart, dateEnd) {
        return axios.get(
            resource +
            groupID +
            "&dateStart=" +
            dateStart +
            "&dateEnd=" +
            dateEnd
        );
    },
};