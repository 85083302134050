import axios from "axios";

const resource = "/people-counting-management/report-excel-by-date?dateStart=";

export default {
    get(dateStart, dateEnd, group, lang) {
        return axios.get(
            resource +
                dateStart +
                "&dateEnd=" +
                dateEnd +
                "&groupID=" +
                group +
                "&lang=" +
                lang +
                "&timestamp=" +
                new Date().getTime(),
            {
                responseType: "blob"
            }
        );
    }
};
