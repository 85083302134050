import axios from "axios";

const resource = "/customer-management/update-customer-type/";

export default {
    put(customerTypeID, data) {
        return axios.put(resource + customerTypeID, data, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
};
