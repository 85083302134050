import axios from "axios";

const resource = "employee-management/update-employee/";

export default {
    put(id, data) {
        return axios.put(resource + id, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
};