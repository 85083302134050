import axios from 'axios'

const resource = "/report-face-attendance/get-employee-attendance?dateStart="

export default {
    get(dateStart, dateEnd, groupID, sortAtribute, ascending, searchValue, attendanceID, page, limit) {
        return axios.get(resource + dateStart +
            '&dateEnd=' + dateEnd +
            '&groupID=' + groupID +
            '&sortAtribute=' + sortAtribute +
            '&ascending=' + ascending +
            '&searchValue=' + searchValue +
            '&attendanceID=' + attendanceID +
            '&page=' + page +
            '&limit=' + limit)
    }
}