import axios from "axios";

const resource =
    "people-counting-management/retail/dashboard/trending?dateStart="

export default {
    get(dateRange, dateRangeCompare, groupID) {
        return axios.get(
            resource + dateRange["dateStart"] + 
            "&dateEnd=" + dateRange["dateEnd"] + 
            "&dateStartPrevious=" + dateRangeCompare["dateStart"] + 
            "&dateEndPrevious=" + dateRangeCompare["dateEnd"] + 
            "&groupID=" + groupID
        );
    },
};