import axios from "axios";

const resource =
    "/customer-management/list-customer-analyst";

export default {
    get(time, type, page, limit) {
        return axios.get(
            resource +
            "?time=" + time +
            "&type=" + type +
            "&page=" + page +
            "&limit=" + limit
        );
    }
};
