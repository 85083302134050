import axios from "axios";

const resource = "/customer-management/update-customer/";

export default {
    put(id, data) {
        return axios.put(resource+id, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    }
};
