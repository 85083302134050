import axios from "axios";

const resource = "/people-counting-management/compare-overalls?dateStart=";

export default {
    get(dateStart, dateEnd, compareType, groupID, previousRepeat) {
        return axios.get(
            resource +
                dateStart +
                "&dateEnd=" +
                dateEnd +
                "&compareType=" +
                compareType +
                "&groupID=" +
                groupID +
                "&previousRepeat=" + 
                previousRepeat
        );
    }
};
