import axios from "axios";
import utils from "@/utils";

const resource = "customer-management/export-excel-customer-history?";

export default {
    post(dateStart, dateEnd, searchValue, sortAttribute, ascending, customerTypeFilter, groupNameFilter, lang) {
        const paramsObject = {
            dateStart: dateStart,
            dateEnd: dateEnd,
            searchValue: searchValue,
            sortAttribute: sortAttribute,
            ascending: ascending,
            customerTypeFilter: customerTypeFilter,
            groupFilter: groupNameFilter,
            isMaskedFaceFilter: "",
            familiarCustomer: false,
            lang: lang
        };

        const queryString = utils.serializeQueryParams(paramsObject);
        return axios.post(
            `${resource}${queryString}`,
            null,
            {responseType: "blob"}
        );
    }
};
