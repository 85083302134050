import axios from "axios";

const resource = "customer/send-classified-image";

export default {
  post(data) {
      return axios.post(resource, data, {
          headers: {
              "Content-Type": "application/json",
          },
      });
  },
};
