import axios from "axios";

const resource =
    "/people-counting-management/statistic-gate-by-hour?dateStart=";

export default {
    get(dateStart, dateEnd, groupID) {
        return axios.get(
            resource +
                dateStart +
                "&dateEnd=" +
                dateEnd +
                "&groupID=" +
                groupID
        );
    }
};
