import axios from "axios";

const resource = "/people-counting-management/ranked-group?dateStart=";

export default {
    get(dateStart, dateEnd) {
        return axios.get(
            resource +
                dateStart +
                "&dateEnd=" +
                dateEnd
        );
    }
};
