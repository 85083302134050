import axios from "axios";

const resource = "/people-counting-management/list-static-overall?dateStart=";

export default {
    async get(dateStart, previousType, groupID) {
        return await axios.get(
            resource +
                dateStart +
                "&previousType=" +
                previousType +
                "&groupID=" +
                groupID
        );
    },
};
