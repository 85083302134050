import axios from "axios";

const resource = "/people-counting-management/statistic-detail?dateStart=";

export default {
    get(dateStart, dateEnd, compareType, groupID) {
        return axios.get(
            resource +
                dateStart +
                "&dateEnd=" +
                dateEnd +
                "&compareType=" +
                compareType +
                "&groupID=" +
                groupID
        );
    }
};
