import axios from "axios";

const resource = "/people-counting-management/compare-all-shop?dateStart=";

export default {
    get(dateStart, dateEnd, attributeType, resultType) {
        return axios.get(
            resource +
                dateStart +
                "&dateEnd=" +
                dateEnd +
                "&attributeType=" +
                attributeType +
                "&resultType=" +
                resultType
        );
    }
};
