import axios from "axios";

const resource = "/report-face-attendance/add-customer-other-way?timestamp=";

export default {
    post(data, id, timestamp, groupName) {
        return axios.post(
            resource + timestamp + "&groupName=" + groupName + "&id=" + id,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
    },
};
