import axios from "axios";

const resource =
    "people-counting-management/shopping-mall/report/report-excel-by-date";

export default {
    get(dateStart, dateEnd, lang, timestamp) {
        return axios.get(resource + "?dateStart=" + dateStart + "&dateEnd=" + dateEnd + "&lang=" + lang + "&timestamp=" + timestamp,
        {
            responseType: "blob"
        });
    },
};