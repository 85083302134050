import axios from 'axios'

const resource = "/report-heatmap/count-by-date?dateStart=";


export default {
    get(dateStart, dateEnd, groupID, compare) {
        return axios.get(
            resource + dateStart + "&dateEnd=" + dateEnd + "&groupID=" + groupID + "&compare=" + compare
        );
    }
}