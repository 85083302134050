import axios from 'axios';

const resource = '/employee-management/download-list-employee?';

export default {
    post(listGroupFilter, sortAtribute, ascending, searchValue, departmentFilter) {
        return axios.post(resource +
            'listGroupFilter=' + listGroupFilter +
            '&sortAtribute=' + sortAtribute +
            '&ascending=' + ascending +
            '&searchValue=' + searchValue +
            '&departmentFilter=' + departmentFilter,
            null, { responseType: 'blob' });
    }
};

