import axios from "axios";

const resource = "customer-management/report-customer-history?dateStart=";

export default {
    get(
        dateStart,
        dateEnd,
        searchValue,
        sortAtribute,
        ascending,
        customerTypeFilter,
        groupNameFilter,
        page,
        limit,
        isMaskedFaceFilter,
        familiarCustomer
    ) {
        return axios.get(
            resource +
                dateStart +
                "&dateEnd=" +
                dateEnd +
                "&searchValue=" +
                searchValue +
                "&sortAttribute=" +
                sortAtribute + 
                "&ascending=" +
                ascending + 
                "&customerTypeFilter=" +
                customerTypeFilter + 
                "&groupFilter=" +
                groupNameFilter +  
                "&page=" +
                page +
                "&limit=" +
                limit + 
                "&isMaskedFaceFilter=" +
                isMaskedFaceFilter + 
                "&familiarCustomer=" +
                familiarCustomer + "&lang=" + localStorage.lang 
        );
    }
};