import axios from 'axios';

const resource = "/report-heatmap/report-heatmap-layout?dateStart=";


export default {
    get(dateStart, dateEnd, groupID, layoutID) {
        return axios.get(
            `${resource}${dateStart}&dateEnd=${dateEnd}&groupID=${groupID}&layoutID=${layoutID}`
        );
    }
};