import axios from "axios";

const resource = "/customer-management/customer-behavior?customerID=";

export default {
    get(customerID, page, limit) {
        return axios.get(
            resource + customerID + "&page=" + page + "&limit=" + limit + "&lang=" + localStorage.lang
        );
    },
};
