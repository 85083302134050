import axios from "axios";

const resource = "/customer-management/download-list-customer";

export default {
    post(
        searchValue,
        sortAtribute,
        ascending,
        genderFilter,
        ageFilter,
        customerTypeFilter,
        lang
    ) {
        return axios.post(
            resource +
                "?searchValue=" +
                searchValue +
                "&sortAtribute=" +
                sortAtribute +
                "&ascending=" +
                ascending +
                "&genderFilter=" +
                genderFilter +
                "&ageFilter=" +
                ageFilter +
                "&customerTypeFilter=" +
                customerTypeFilter +
                "&lang=" +
                lang,
            null,
            { responseType: "blob" }
        );
    }
};
