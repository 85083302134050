import axios from 'axios'

const resource = "/employee-management/list-employee"

export default {
    get(searchValue, departmentFilter, listGroupFilter, sortAtribute, ascending, page, limit) {
        return axios.get(
            resource +
                "?searchValue=" +
                searchValue +
                "&sortAtribute=" +
                sortAtribute +
                "&ascending=" +
                ascending +
                "&departmentFilter=" +
                departmentFilter +
                "&listGroupFilter=" +
                listGroupFilter +
                "&page=" +
                page +
                "&limit=" +
                limit
        );
    }
}