import axios from "axios";

const resource = "/customer-management/add-customer";

export default {
    post(data) {
        return axios.post(resource, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
};
